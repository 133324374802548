import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('cookieNotice', (config) => ({
    tab: 'overview',
    init() {
         window.CookieNotice.boot(this.$root, config);
    },
    show() {
        window.CookieNotice.showWidget();
    },
    hide() {
        window.CookieNotice.hideWidget();
    },
    acceptAll() {
        window.CookieNotice.config.consent_groups.forEach((consentGroup) => {
            window.CookieNotice.widget.querySelector(`[name='group-${consentGroup.handle}']`).checked = true;
        });
        window.CookieNotice.savePreferences();
    },
}));

Alpine.data('consentGate', ({ category }) => ({
    consent: false,
    init() {
        window.CookieNotice.on('accepted', (group) => {
            if (group === category) {
                this.consent = true;
            }
        });
    },
}));