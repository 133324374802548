import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('teaser_video', (jwpID, ratioKey) => ({
    API_URL: null,
    videoID: jwpID,
    ratioKey: ratioKey,
    source: false,
    error: false,
    currentDimension: 'height',
    init() {
        if (this.videoID === null || this.videoID === undefined || this.videoID === "") {
            console.error("Video ID was not set");
            return;
        }
        this.API_URL = 'https://cdn.jwplayer.com/v2/media/' + this.videoID + '?format=json';
    },
    async loadMP4() {
        let response = await fetch(this.API_URL);
        let json = await response.json();

        if (response.status === 200) {
            let selectedSource = 360;

            if (window.innerWidth > 768 && window.innerWidth < 1000) {
                selectedSource = 540;
            } else if (window.innerWidth > 1000) {
                selectedSource = 720;
            }

            let availableMP4Sources = json.playlist[0].sources.filter(o => (o.type === 'video/mp4'));
            let elementProp = this.currentDimension;
            let closestSource = availableMP4Sources.reduce(function(prev, curr) {
                if (elementProp === 'height') {
                    return (Math.abs(curr.height - selectedSource) < Math.abs(prev.height - selectedSource) ? curr : prev);
                } else if (elementProp === 'width') {
                    return (Math.abs(curr.width - selectedSource) < Math.abs(prev.width - selectedSource) ? curr : prev);
                }
            });

            this.source = closestSource.file;
        } else {
            this.error = true;
        }
    },
    initMP4File() {
        if (this.videoID && this.source === false) {
            this.loadMP4();
        }
    },
    start() {
        if (this.source) {
            this.$refs.teaser_video.play();
        }
    },
    stop() {
        if (this.source) {
            this.$refs.teaser_video.pause();
        }
    }
}));
