import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data("video_player", ({
  uid,
  jwpMediaId,
  mp4,
  aspectRatio,
  autoPlay,
  mute,
  chromeless,
  loop,
  pauseWhenOthersPlay,
  pauseWhenNotVisible
}) => ({
  uid: uid,
  jwpMediaId: jwpMediaId,
  mp4: mp4,
  aspectRatio: aspectRatio,
  autoPlay: autoPlay,
  mute: mute,
  chromeless: chromeless,
  loop: loop,
  pauseWhenOthersPlay: pauseWhenOthersPlay,
  pauseWhenNotVisible: pauseWhenNotVisible,
  API_URL: `https://cdn.jwplayer.com/v2/media/${ jwpMediaId }?format=json`,
  jwPlayer: null,
  source: null,
  init() {
    if (!this.mp4) {
      this.jwPlayer = jwplayer(this.$refs.video).setup(this.jwOptions());
      this.registerPlayer();
      this.attachEvents();
    }
  },
  registerPlayer() {
    // The reels script (js/alpine/ReelsPlayer) defines a callback
    // function stored under the UID in window.frop_players to be
    // run here once the JWPlayer has initialised.
    if (window.frop_players === undefined) window.frop_players = {};
    if (window.frop_players_pause === undefined) window.frop_players_pause = {};
    const cb = window.frop_players[this.uid];
    window.frop_players[this.uid] = this.jwPlayer;
    if (typeof cb === "function") cb();
    if (this.pauseWhenOthersPlay) window.frop_players_pause[uid] = this.jwPlayer;
  },
  jwOptions () {
    return {
      playlist: `https://cdn.jwplayer.com/v2/media/${ this.jwpMediaId }`,
      width: '100%',
      height: '100%',
      aspectratio: `${ this.aspectRatio }`,
      autostart: this.autoPlay,
      mute: this.mute,
      controls: !this.chromeless,
      repeat: this.loop,
      autoPause: { viewability: this.pauseWhenNotVisible }
    };
  },
  aspectRatioFloat () {
    return this.aspectRatio.split('/').map(e => Number.parseFloat(e)).reduce((acc, e) => e/acc, 1);
  },
  async loadMP4 () {
    if (this.source) return;
    const ar = this.aspectRatioFloat();
    const h = this.$refs.video.offsetWidth * ar;
    const response = await fetch(this.API_URL);
    const json = await response.json();
    if (response.status === 200) {
      let availableMP4Sources = json.playlist[0].sources.filter(o => (o.type === 'video/mp4'));
      let i = 0;
      while (!this.source && i < availableMP4Sources.length) {
        let candidate = availableMP4Sources[i];
        if (candidate.height > h) {
          this.source = candidate.file;
        }
        i++;
      }
    }
  },
  attachEvents () {
    this.jwPlayer.on("play", this.play.bind(this));
  },
  play (evt) {
    Object.keys(window.frop_players_pause).forEach((playerId) => {
      let player = window.frop_players_pause[playerId];
      if (playerId !== this.uid && player.getState() == "playing") {
        player.pause();
      }
    });
  },
  intersectStart () {
    if (this.autoPlay) {
      if (mp4) {
        this.$refs.html_video.play();
      } else {
        this.jwPlayer.play();
      }
    }
  },
  intersectStop () {
    if (this.pauseWhenNotVisible) {
      if (mp4) {
        this.$refs.html_video.pause();
      } else {
        this.jwPlayer.pause();
      }
    }
  }
}));
