// Danish month names
const monthNames = ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];
const longMonthNames = ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'];

// Dates for comparison
let now = null;
let aYearAgo = null;
let aDayAgo = null;
let fiftyNineMinutesAgo = null;
let aMinuteAgo = null;
const liveElements = [];

const setComparisonDates = () => {
  now = new Date();
  aYearAgo = new Date();
  aDayAgo = new Date();
  fiftyNineMinutesAgo = new Date();
  aMinuteAgo = new Date();
  aYearAgo.setFullYear(aYearAgo.getFullYear() - 1);
  aDayAgo.setDate(aDayAgo.getDate() - 1);
  fiftyNineMinutesAgo.setMinutes(fiftyNineMinutesAgo.getMinutes() - 59);
  aMinuteAgo.setMinutes(aMinuteAgo.getMinutes() - 1);
}

setComparisonDates();

const live_relative_time = (date, el, longMonths = false) => {
  liveElements.push({ el, date, longMonths });
  requestAnimationFrame(() => {
    el.innerText = relative_time(date, longMonths);
  });
}

setInterval(() => {
  setComparisonDates();
  liveElements.forEach(({ date, el, longMonths }) => {
    el.innerText = relative_time(date, longMonths);
  });
}, 30 * 1000);

const relative_time = (date, longMonths = false) => {
  // Less than 1 minute ago
  if (date > aMinuteAgo) {
    return `lige nu`;
  }
  // Less than 59 minutes ago
  if (date > fiftyNineMinutesAgo) {
    return `for ${Math.floor(((now - date)/1000)/60)} min. siden`;
  }
  // More than 59 minutes ago, but less than a day ago
  if (date > aDayAgo) {
    const dag_eller_gaar = (date.getDate() == now.getDate()) ? 'dag' : 'går';
    const hh = date.getHours().toString().padStart(2, '0');
    const mm = date.getMinutes().toString().padStart(2, '0');
    return `i ${dag_eller_gaar} kl. ${hh}:${mm}`;
  }
  // More than a day ago, but less than a year ago
  if (date > aYearAgo) {
    return `${date.getDate()}. ${longMonths ? longMonthNames[date.getMonth()] : monthNames[date.getMonth()]}`;
  }
  // More than a year ago
  return `${date.getDate()}. ${longMonths ? longMonthNames[date.getMonth()] : monthNames[date.getMonth()]} ${date.getFullYear()}`;
};

const calc_relative_time = () => {
  Array.from(document.querySelectorAll('time.relative_time[datetime]')).forEach((element) => {
    element.innerText = relative_time(new Date(element.getAttribute('datetime')));
  });
};

window.relative_time = relative_time;
window.live_relative_time = live_relative_time;

export default calc_relative_time;