import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data("topmenu", () => ({
    hide: false,
    start: 0,
    timer: null,
    shouldHide: false,
    scrollHandler(evt, triggeredByTimeout = false) {
        let scroll = window.scrollY;
        let megaMenuVisible = Alpine.store("megaMenuShow");
        if (!megaMenuVisible) {
            if (scroll < 40 || scroll < this.start - 200) {
                // show the menu
                this.shouldHide = false;
            } else if (scroll >= this.start + 10) {
                // hide the menu
                this.shouldHide = true;
            }
            if (this.timer) {
                clearTimeout(this.timer);
            }
            if (!triggeredByTimeout) {
                this.timer = setTimeout(
                    this.scrollHandler.bind(this, {}, true),
                    250
                );
            }
        }
        if (this.hide != this.shouldHide) {
            this.hide = this.shouldHide;
            this.$el.dispatchEvent(
                new CustomEvent("navVisibilityChange", {
                    detail: { hidden: this.hide },
                    bubbles: true,
                })
            );
        }
        this.start = scroll;
    },
}));
